import {
    FetchBaseQueryError,
    createApi,
    fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import {
    AuthResponse,
    ChangePasswordRequest,
    LoginRequest,
    EmailLoginRequest,
    EmailLoginLinkRequest,
    NewPasswordRequest,
    ResetPasswordRequest,
    SendVerificationRequest,
    SignUpRequest,
    Credentials,
} from "./types"
import { transformErrorResponse } from "utils"
import { ENV } from "config"
import { setTokens } from "./utils/storage"
import { baseQueryWithReauth } from "./utils/query"

const baseQuery = fetchBaseQuery({baseUrl: `${ENV.ALEXIARES_HOST_URL}/users/`})

export const authenticationAPI = createApi({
    reducerPath: 'authenticationAPI',
    baseQuery,
    endpoints: (builder) => ({
        login: builder.mutation<Credentials, LoginRequest>({
            query: (body) => ({
                url: '/login',
                method: 'POST',
                body,
            }),
            transformResponse: async (response: AuthResponse) => await setTokens(response),
            transformErrorResponse,
        }),
        signUp: builder.mutation<Credentials | null, SignUpRequest>({
            query: (body) => ({
                url: '/registration',
                method: 'POST',
                body,
            }),
            transformResponse: async (response: AuthResponse) => {
                if (response.access_token && response.refresh_token) {
                    return await setTokens(response)
                }
                return null
            },
            transformErrorResponse,
        }),
        emailLogin: builder.mutation<AuthResponse, EmailLoginRequest>({
            queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
                const requestResult = await baseQuery(
                    {
                        url: `/login`,
                        method: "POST",
                        body: _arg,
                    },
                    _queryApi,
                    _extraOptions
                )
                if (requestResult.error) {
                    return {
                        error: transformErrorResponse(
                            requestResult.error,
                            requestResult.meta ?? ({} as any),
                            null
                        ) as FetchBaseQueryError,
                    }
                }
                return { data: requestResult.data as AuthResponse }
            },
        }),
        emailLoginLink: builder.mutation<null, EmailLoginLinkRequest>({
            queryFn: async (body, api, extraOptions) => {
                const response = await baseQuery(
                    {
                       url: `/loginlink`,
                       method: "POST",
                       body,
                    },
                    api,
                    extraOptions,
                )
        
                if (response.error) {
                    return {
                        error: transformErrorResponse(
                            response.error,
                            response.meta ?? ({} as any),
                            null
                        ) as FetchBaseQueryError,
                    }
                }

                return {data: null}
            },
        }),
        // Sends a reset password link via email to the email address (if no such email exists, we don't send an email)
        resetPassword: builder.mutation<null, ResetPasswordRequest>({
            query: (body) => ({
                url: '/resetpassword',
                method: 'POST',
                body,
            }),
            transformErrorResponse,
        }),
        // Updates the user password using the token sent to the user via email
        newPassword: builder.mutation<null, NewPasswordRequest>({
            query: ({reset_password_token, ...body}) => ({
                url: '/newpassword',
                method: 'POST',
                body,
                headers: {Authorization: `Bearer ${reset_password_token}`},
            }),
            transformErrorResponse,
        }),
        // Resends the user's verification email as long as the requested user has not already confirmed
        sendVerificationEmail: builder.mutation<null, SendVerificationRequest>({
            query: (body) => ({
                url: '/verifications/email',
                method: 'POST',
                body,
            }),
            transformErrorResponse,
        }),
        // Checks that the current_password matches the user's current password and then changes the user's password. Because the user has authenticated, the response from a successful request is a TokenData
        changePassword: builder.mutation<AuthResponse, ChangePasswordRequest>({
            queryFn: async (body, api, extraOptions) => {
                const response = await baseQueryWithReauth(
                    baseQuery,
                    {
                        url: '/passwordupdate',
                        method: 'POST',
                        body,
                    },
                    api,
                    extraOptions
                )
        
                if (response.error) {
                    return {
                        error: transformErrorResponse(
                            response.error,
                            response.meta ?? ({} as any),
                            null
                        ) as FetchBaseQueryError,
                    }
                }

                await setTokens(response.data as AuthResponse)
                return {data: response.data as AuthResponse}
            },
        }),
    }),
})

export const {
    useChangePasswordMutation,
    useLoginMutation,
    useEmailLoginMutation,
    useEmailLoginLinkMutation,
    useSignUpMutation,
    useNewPasswordMutation,
    useResetPasswordMutation,
    useSendVerificationEmailMutation,
} = authenticationAPI
